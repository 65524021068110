import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Step Wizard`}</inlineCode>{` merupakan serangkaian langkah yang memungkinkan user untuk memasukkan informasi dengan urutan yang sudah ditentukan, membantu user menyelesaikan proses yang panjang dan asing.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1000px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABHUlEQVQoz42S6U6FMBCF+/5PaNTkasKSoFBu12kLOXZDgUvUH1+YDtPTzukwbT0SyrgHdOWY95VdXdZwGVY2UE0UcU2hsIvN9s+4Y226zC5mwyTRDTNEPPWuXEbOCuoeURZK6AyXDkK7vDnVplhqeuiKtaPF061HG0W5CuB6gWo6mOcXUN/Dcw7HZzQjoZkcBhEgaYV2C4RU8cYWPgSEZQG5AGbJx3aSVwTnQ8aGFcaVNq1fM6m4eB1zcU9a22qFIV/i+GXjrDFOAlKY2EJpWemrx9ge4Dr/3fJ7O+L2+oah6bMvH3eKfhG6T5kPOJufbrjPnWEqGjtH02V9flNHSFSxbVR+EzkIZhEKh1ncz+bPjP1T8GpAz7n9IX/xBZpXB1l/ELwpAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/b834f95392e23aaa5a4fc79b43646429/ea415/default.png",
              "srcSet": ["/static/b834f95392e23aaa5a4fc79b43646429/7fc1e/default.png 288w", "/static/b834f95392e23aaa5a4fc79b43646429/a5df1/default.png 576w", "/static/b834f95392e23aaa5a4fc79b43646429/ea415/default.png 1000w"],
              "sizes": "(max-width: 1000px) 100vw, 1000px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      